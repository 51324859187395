import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/mswsn/projects/vcs-childhood-elearning-q2-2020/src/components/layout.tsx";
import { Navigation } from "../components/navigation";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Childhood och vårt samarbete`}</h1>
    <p>{`World Childhood Foundation grundades år 1999 av Drottning Silvia med syftet att värna om barns rätt till en trygg och kärleksfull barndom, och särskilt arbeta för att förbättra levnadsvillkoren för barn som riskerar att utsättas för våld eller sexuella övergrepp.
Childhood har sedan starten verkat för att synliggöra frågor som rör sexuell exploatering av barn och problematiken kring barnhem. Organisationen stödjer idag cirka 100 projekt i 14 länder och fokuserar på att stärka familjer och föräldrar, så kallad ”protective family environment”. Sedan år 1999 har över tusen projekt fått stöd av Childhood, och tusentals barn fått en tryggare barndom, fri från våld och sexuella övergrepp.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGS1jkxhsH/xAAbEAEAAgIDAAAAAAAAAAAAAAABAAIDEQQSE//aAAgBAQABBQLWypViR5AU8C2LrWf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFH/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQIBAT8BU//EABsQAAIDAAMAAAAAAAAAAAAAAAABERMhAjFR/9oACAEBAAY/ApOeKfWdldagzDVLP//EABsQAAIDAAMAAAAAAAAAAAAAAAARASExQXGR/9oACAEBAAE/IWhSFgvP4SYW4LCO6LWWXCGewf/aAAwDAQACAAMAAAAQww//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EKU//8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIUFhMVGB/9oACAEBAAE/EKixaBbe4K1HbBStrvzDQh2DzGDQ1RrdyjuIFEqpbntvY7KTFKX84n//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Drottning Silvia presentation",
          "title": "Drottning Silvia presentation",
          "src": "/static/d86e3aa40de751c8a0bc8f67ba4b8702/e5166/queen-silvia.jpg",
          "srcSet": ["/static/d86e3aa40de751c8a0bc8f67ba4b8702/f93b5/queen-silvia.jpg 300w", "/static/d86e3aa40de751c8a0bc8f67ba4b8702/b4294/queen-silvia.jpg 600w", "/static/d86e3aa40de751c8a0bc8f67ba4b8702/e5166/queen-silvia.jpg 1200w", "/static/d86e3aa40de751c8a0bc8f67ba4b8702/d9c39/queen-silvia.jpg 1800w", "/static/d86e3aa40de751c8a0bc8f67ba4b8702/df51d/queen-silvia.jpg 2400w", "/static/d86e3aa40de751c8a0bc8f67ba4b8702/9b601/queen-silvia.jpg 5760w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Childhood arbetar framförallt med de grupper som löper störst risk att utsättas för våld och sexuella övergrepp. Till dem hör barn som lever på gatan, barn som redan utsatts för våld och övergrepp, barn som inte kan bo med sina föräldrar eller barn som lever i en familj där föräldrarna (av olika skäl) inte orkar vara det som barnen mest behöver – viktiga vuxna.`}</p>
    <p>{`Vårt samarbete med Childhood inleddes år 2003 och är någonting som går hand i hand med vårt engagemang gällande barns säkerhet. Därför skänker Volvo Car Sverige och svensk Volvohandel pengar till Childhood för varje såld bil i Sverige. Dessutom utbildar vi våra medarbetare, dig, i hur du kan bli en viktig vuxen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAEqzpjjbV//xAAaEAACAgMAAAAAAAAAAAAAAAABAhESAyEx/9oACAEBAAEFAlUzUQcQkAM7cvr/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAEAExERJR/9oACAEBAAY/As0rNuKD/8QAGRABAAMBAQAAAAAAAAAAAAAAAQAhQRFx/9oACAEBAAE/ITW8JEO9dZpI5Raln5BWGT//2gAMAwEAAgADAAAAEL/P/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxCcW//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EIf/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhMUFR/9oACAEBAAE/ED9TSWt5zHTFS+w/ccmF26HJDAAVTWMPOrdzSlUPc//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Barn sitter vid räls",
          "title": "Barn sitter vid räls",
          "src": "/static/e705d1fd326406c905045500498da684/e5166/railroad.jpg",
          "srcSet": ["/static/e705d1fd326406c905045500498da684/f93b5/railroad.jpg 300w", "/static/e705d1fd326406c905045500498da684/b4294/railroad.jpg 600w", "/static/e705d1fd326406c905045500498da684/e5166/railroad.jpg 1200w", "/static/e705d1fd326406c905045500498da684/d9c39/railroad.jpg 1800w", "/static/e705d1fd326406c905045500498da684/df51d/railroad.jpg 2400w", "/static/e705d1fd326406c905045500498da684/e8277/railroad.jpg 6016w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Därför ska vi nu titta närmare på hur situationen för barn ser ut idag, vad det innebär att vara en viktig vuxen och hur du kan agera i olika situationer.`}</p>
    <Navigation next={{
      chapter: "Kapitel 2",
      title: "Barnens vardag",
      link: "kapitel-2"
    }} mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      